import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { RequestInterfaceNew } from '@src/interfaces'
import { WorkScheduleBasicStep } from '@src/interfaces/workSchedule'

export const workingScheduleBasicRequests: RequestInterfaceNew<WorkScheduleBasicStep> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/basicStep/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/basicStep/${id}`, data),
  submit: async data =>
    apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/basicStep`, data),
}
