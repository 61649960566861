import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { useLocation } from 'react-router-dom'

export interface GoalData {
  is_company?: boolean
  is_top_level?: boolean
  parent?: { id: number } | null
}

const searchParamsToObject = (search: URLSearchParams) => {
  return [...search.entries()].reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {} as Record<string, string>)
}

export const useGetSwitchFilters = (goalData: GoalData) => {
  const location = useLocation()
  const search = location.search
  const searchParams = searchParamsToObject(new URLSearchParams(search))
  const goalsFilterParams: FilterByInterface[] = [
    {
      columnName: 'status',
      filters: [
        { id: Statuses.at_risk, name: Statuses.at_risk },
        { id: Statuses.on_track, name: Statuses.on_track },
        { id: Statuses.delayed, name: Statuses.delayed },
        { id: Statuses.on_hold, name: Statuses.on_hold },
        { id: Statuses.complete, name: Statuses.complete },
      ],
    },
  ]
  if (goalData.is_company) {
    goalsFilterParams.push({
      columnName: 'is_company',
      filters: [{ id: 'True', name: 'True' }],
    })
  }
  if (goalData.is_top_level) {
    goalsFilterParams.push({
      columnName: 'is_top_level',
      filters: [{ id: 'True', name: 'True' }],
    })
  }
  if (goalData.parent) {
    goalsFilterParams.push({
      columnName: 'parent_id',
      filters: [{ id: goalData.parent.id, name: 'parent_id' }],
    })
  }
  if (searchParams?.cycle__id) {
    goalsFilterParams.push({
      columnName: 'cycle__id',
      filters: [{ id: searchParams.cycle__id, name: 'cycle__id' }],
    })
  }
  if (searchParams?.content_type_id) {
    goalsFilterParams.push({
      columnName: 'content_type_id',
      filters: [{ id: searchParams.content_type_id, name: 'content_type_id' }],
    })
  }
  if (searchParams?.object_id) {
    goalsFilterParams.push({
      columnName: 'object_id',
      filters: [{ id: searchParams.object_id, name: 'object_id' }],
    })
  }
  return { filters: goalsFilterParams, searchParams }
}
