import React, { useState } from 'react'
import styled from 'styled-components'

import Dialog from '../../Modals/Dialog/Dialog'
import Tooltip from '../../Tooltip/Tooltip'
import { Box, ElevationLevel, Relative, Token } from '@revolut/ui-kit'

interface PopupChartInterface {
  vertical?: 'left' | 'right'
  children: (fullScreen: boolean) => React.ReactNode
  trigger: React.ReactNode
  enlargeFromAnywhere?: boolean
}

const dropdownHeight = 340
const dropdownWidth = 620

const ChartDropdown = styled(Box)<{
  vertical?: 'left' | 'right'
  horizontal?: 'top' | 'bottom'
}>`
  font-size: 14px;
  position: absolute;
  bottom: 32px;
  ${({ horizontal }) => (horizontal === 'top' ? 'bottom: -30px' : 'top: -15px')};
  ${({ vertical }) => (vertical === 'right' ? `left: 0` : `right: 0px`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 100ms linear;
  background: ${Token.color.widgetBackground};
  width: ${dropdownWidth}px;
  height: ${dropdownHeight}px;
  z-index: ${({ theme }) => theme.zIndex.popup};
  border-radius: 12px;
`

const ChartDropdownContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
`

const PopupChartStyled = styled(Dialog)`
  animation-name: none;
  padding: 0.5rem;
`

const Wrapper = styled.div`
  position: relative;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 100ms linear;
  color: ${Token.color.greyTone50};
`

const MaximizeText = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  height: 10px;
  color: ${Token.color.greyTone20};
  cursor: pointer;
`

const Container = styled.div`
  display: grid;
  flex: 1;
`

const PopupChart = ({
  vertical,
  children,
  trigger,
  enlargeFromAnywhere = true,
}: PopupChartInterface) => {
  const [hoveredPositionX, updateHoverX] = useState<'right' | 'left' | undefined>()
  const [hoveredPositionY, updateHoverY] = useState<'top' | 'bottom' | undefined>()
  const [openBigChart, setOpenBigChart] = useState(false)

  const handleChartClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    updateHoverX(undefined)
    updateHoverY(undefined)
    setOpenBigChart(true)
  }

  const updateHoverPosition = (e: React.MouseEvent) => {
    const positionX = window.innerWidth - e.pageX - 80 > dropdownWidth ? 'right' : 'left'
    const positionY =
      window.innerHeight - e.pageY - 40 > dropdownHeight ? 'bottom' : 'top'

    updateHoverY(positionY)
    updateHoverX(positionX)
  }

  const maximizeText = enlargeFromAnywhere
    ? 'Click anywhere to enlarge'
    : 'Click to enlarge'

  return (
    <Container>
      <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <PopupChartStyled
          open={openBigChart}
          onClose={() => setOpenBigChart(false)}
          showCloseButton
        >
          <Relative
            width={{ all: '90vw', md: '70vw', lg: '70vw' }}
            height={{ all: '35vh', md: '40vh', lg: '70vh' }}
          >
            {children(openBigChart)}
          </Relative>
        </PopupChartStyled>
      </div>
      <Tooltip
        placement={hoveredPositionY || 'top'}
        hide={!hoveredPositionY}
        noArrow
        body={
          <ChartDropdown
            elevation={ElevationLevel.L_250}
            vertical={vertical || hoveredPositionX}
            horizontal={hoveredPositionY}
            onClick={handleChartClick}
          >
            <MaximizeText>{maximizeText}</MaximizeText>
            <ChartDropdownContainer>{children(openBigChart)}</ChartDropdownContainer>
          </ChartDropdown>
        }
      >
        <Wrapper onClick={handleChartClick} onMouseEnter={updateHoverPosition}>
          {trigger}
        </Wrapper>
      </Tooltip>
    </Container>
  )
}

export default PopupChart
