import React, { useState } from 'react'
import styled from 'styled-components'
import omit from 'lodash/omit'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button as NewButton, MoreBar, Token } from '@revolut/ui-kit'

import ConfirmationDialog from '../../features/Popups/ConfirmationDialog'
import Tooltip from '../../components/Tooltip/Tooltip'

import { FieldOptions, Statuses } from '@src/interfaces'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { AccessRequestInterface } from '@src/interfaces/accessRequest'
import { accessRequests } from '@src/api/accessRequests'
import { ROUTES } from '@src/constants/routes'
import { AxiosPromise } from 'axios'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { successNotification } from '@src/actions/NotificationActions'
import LapeDescriptionDialog from '@src/features/Popups/LapeDescriptionDialog'
import { connect } from 'lape'
import { State } from '@src/store'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const StyledDraftBtn = styled.button`
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  outline: none;
  color: ${Token.color.blue};
  align-self: center;

  &:disabled {
    color: ${Token.color.blue_60};
    cursor: not-allowed;
  }
`

export const SumbitButtonWrapper = styled.div`
  display: grid;
  justify-content: end;
`

export const DraftTooltip = styled(Tooltip)`
  height: 100%;
`

export const CancelAccessRequestButton = ({
  afterSubmitUrl,
}: {
  afterSubmitUrl: string
}) => {
  const [submitting, setSubmitting] = useState(false)
  const params = useParams()
  const { values, initialValues } = useLapeContext<AccessRequestInterface>()

  let canCancel =
    initialValues.status === Statuses.pending &&
    values.field_options?.permissions?.includes(PermissionTypes.CancelAccessrequests)

  const cancel = async () => {
    setSubmitting(true)
    try {
      const result = await accessRequests?.putItem?.(
        { ...values, status: Statuses.canceled },
        values.id,
      )
      successNotification(
        'Access request successfully canceled',
        pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.GENERAL, {
          ...params,
          id: result?.data?.id,
        }),
      )
      goBack(afterSubmitUrl)
    } catch (e) {
      setSubmitting(false)
    }
  }

  if (!canCancel) {
    return null
  }

  return (
    <NewButton size="sm" variant="negative" onClick={cancel} pending={submitting}>
      Cancel
    </NewButton>
  )
}

interface LapeRejectButtonProps {
  afterSubmitUrl?: string
  notification?: {
    path?: string
    updateMsg: string
  }
  dialog: {
    title: string
    placeholder: string
    fieldName: string
  }
}

export const LapeRejectButton = connect(
  ({ afterSubmitUrl, notification, dialog }: LapeRejectButtonProps) => {
    const [pending, setPending] = useState(false)
    const { values, initialValues, submit } = useLapeContext<{
      id: string
      field_options: { permissions: string[]; actions: string[] }
      status: string | { id: string; name: string }
    }>()
    const permissions = values.field_options || []
    const params = useParams()

    let canReject = false
    let showButton = false

    if (
      initialValues.status === Statuses.pending ||
      initialValues.status === Statuses.preapproved
    ) {
      canReject =
        permissions?.actions?.includes(EntityPermissions.Approve) ||
        permissions?.actions?.includes(EntityPermissions.ApprovePending)
      showButton = true
    }

    const [openedDialog, setOpenedDialog] = useState(false)

    const onSubmit = async (fallbackStatus: string | { id: string; name: string }) => {
      try {
        setPending(true)
        const result = await submit()
        if (afterSubmitUrl) {
          goBack(afterSubmitUrl)
        }
        if (notification) {
          successNotification(
            notification.updateMsg,
            notification.path
              ? pathToUrl(notification.path, { ...params, id: result?.id })
              : undefined,
          )
        }
      } catch {
        values.status = fallbackStatus
      } finally {
        setPending(false)
      }
    }

    const reject = () => {
      const fallbackStatus = values.status
      values.status = Statuses.rejected
      onSubmit(fallbackStatus)
    }

    if (!canReject || !showButton) {
      return null
    }

    return (
      <>
        {openedDialog && (
          <LapeDescriptionDialog
            loading={pending}
            onSubmit={reject}
            onClose={() => setOpenedDialog(false)}
            data={dialog}
          />
        )}
        <NewButton
          useIcon="CrossSmall"
          size="sm"
          variant="negative"
          pending={pending}
          onClick={() => setOpenedDialog(true)}
        >
          Reject
        </NewButton>
      </>
    )
  },
)

interface LapeApproveButtonProps {
  afterSubmitUrl: string
  notification?: {
    path?: string
    updateMsg: string
  }
}

export const LapeApproveButton = connect(
  ({ afterSubmitUrl, notification }: LapeApproveButtonProps) => {
    const [pending, setPending] = useState(false)
    const { values, initialValues, submit, dirty } = useLapeContext<{
      id: string
      field_options: { permissions: string[]; actions: string[] }
      status: string | { id: string; name: string }
    }>()
    const permissions = values.field_options || []
    const params = useParams()

    let canApprove = false
    let showButton = false

    if (
      initialValues.status === Statuses.pending ||
      initialValues.status === Statuses.preapproved
    ) {
      canApprove =
        permissions?.actions?.includes(EntityPermissions.Approve) ||
        permissions?.actions?.includes(EntityPermissions.ApprovePending)
      showButton = true
    }

    const onSubmit = async (fallbackStatus: string | { id: string; name: string }) => {
      values.status = Statuses.approved
      setPending(true)
      try {
        const result = await submit()
        if (afterSubmitUrl) {
          goBack(afterSubmitUrl)
        }
        if (notification) {
          successNotification(
            notification.updateMsg,
            notification.path
              ? pathToUrl(notification.path, { ...params, id: result?.id })
              : undefined,
          )
        }
      } catch {
        values.status = fallbackStatus
      } finally {
        setPending(false)
      }
    }

    const approve = () => {
      const fallbackStatus = values.status
      values.status = Statuses.pending
      onSubmit(fallbackStatus)
    }

    if (!canApprove || !showButton) {
      return null
    }

    return (
      <NewButton
        useIcon="16/StatusCheck"
        variant="secondary"
        size="sm"
        pending={pending}
        onClick={approve}
      >
        {dirty ? 'Save and approve' : 'Approve'}
      </NewButton>
    )
  },
)

type LapeCopyButtonType = 'access-request' | 'hiring-stage'

interface LapeCopyButtonProps {
  type: LapeCopyButtonType
  cleanFields?: string[]
  afterSubmitUrl: string
  replaceValues?: object
}

const canAddType = (type: LapeCopyButtonType) => (state: State) =>
  ({
    'access-request': state.auth.permissions.includes(
      PermissionTypes.CreateAccessRequests,
    ),
    'hiring-stage': true,
  }[type])

export const LapeCopyButton = connect(
  ({ type, afterSubmitUrl, replaceValues, cleanFields }: LapeCopyButtonProps) => {
    const context = useLapeContext<{ id?: string; status?: Statuses }>()
    const { values } = context
    const canSubmit = useSelector(canAddType(type))

    const copy = () => {
      const cleanValues = omit(values, [
        'status',
        'id',
        'field_options',
        'targets',
        'target_epics',
        ...(cleanFields || []),
      ])
      workspaceLocalStorage.setItem(
        afterSubmitUrl,
        JSON.stringify({ ...cleanValues, ...(replaceValues || {}) }),
      )
      // do a hard refresh so the form would remount
      window.location.href = afterSubmitUrl
    }

    if (!values.id || !canSubmit) {
      return null
    }

    return (
      <NewButton useIcon="Copy" size="sm" variant="secondary" onClick={copy}>
        Copy
      </NewButton>
    )
  },
)

interface UpdateButtonProps {
  update: (id: string | number) => Promise<any>
  id: string | number
}

export const UpdateButton = ({ update, id }: UpdateButtonProps) => {
  const [loading, setLoading] = useState(false)
  const handleUpdate = async () => {
    try {
      setLoading(true)
      await update(id)
      pushNotification({
        value:
          'The statistics will be updated in a few seconds, please refresh the page!',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MoreBar.Action useIcon="ArrowExchange" onClick={handleUpdate} pending={loading}>
      Update Stats
    </MoreBar.Action>
  )
}

interface DeleteButtonLapeProps<T> {
  data: T
  deleteApi: (data: T, params: { id: string }) => AxiosPromise<T>
  title: string
  backUrl: string
  useMoreBar?: boolean
}

export const DeleteButtonLape = connect(
  <T extends { id: number; field_options?: FieldOptions; status?: any }>({
    data,
    deleteApi,
    title,
    backUrl,
    useMoreBar = false,
  }: DeleteButtonLapeProps<T>) => {
    const params = useParams<{ id: any }>()
    const [confirmDeleteOpen, updateConfirmDeleteOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const canDelete = data.field_options?.actions?.includes(EntityPermissions.Delete)

    const deleteCallback = async () => {
      setSubmitting(true)
      try {
        await deleteApi(data, params)
      } finally {
        setSubmitting(false)
      }
      goBack(backUrl)
    }

    const openConfirmDelete = () => {
      updateConfirmDeleteOpen(true)
    }
    const closeConfirmDelete = () => {
      updateConfirmDeleteOpen(false)
    }

    if (!data.id || !canDelete) {
      return null
    }

    const isDraft = data.status === Statuses.draft

    const confirmationLabel = isDraft
      ? `Deleting Draft ${title.toLowerCase()}`
      : `Deleting ${title}`
    const confirmationBody = isDraft
      ? 'Are you sure you want to delete this draft?'
      : `Deleting ${title} might affect other items that depend on this ${title}. \n Are you sure you want to delete this item?`

    return (
      <>
        {useMoreBar ? (
          <MoreBar.Action useIcon="Delete" onClick={openConfirmDelete} variant="negative">
            Delete
          </MoreBar.Action>
        ) : (
          <NewButton
            useIcon="Delete"
            size="sm"
            variant="negative"
            onClick={openConfirmDelete}
          >
            Delete
          </NewButton>
        )}
        <ConfirmationDialog
          open={confirmDeleteOpen}
          onClose={closeConfirmDelete}
          onConfirm={deleteCallback}
          loading={submitting}
          onReject={closeConfirmDelete}
          label={confirmationLabel}
          body={confirmationBody}
        />
      </>
    )
  },
)
